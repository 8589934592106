<template>
	<div data-route>
		<page-header
			heading="Create new course"
		/>
		<div data-element="main">
			<course-edit
				:cancelRoute="getCancelRoute"
				:isCreate="true"
			/>
		</div>
	</div>
</template>

<script>

	import PageHeader  from '@/components/ui/PageHeader';
	import CourseEdit  from '@/components/courses/CourseEdit';

	export default {
		metaInfo: {
			title: 'Create new course'
		},
		components: {
			PageHeader,
			CourseEdit
		},
		computed: {
			getCancelRoute () {
				return '/teaching/courses';
			}
		},
		created () {}
	};
</script>
